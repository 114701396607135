import { PRODUCT_IDS } from 'bl-utils/src/ProductIds'

import { MassageState } from '../subflows'

export const getMassageOrFloatNames = (massageOrFloat: MassageState) => {
  if (!massageOrFloat) {
    return ''
  }

  const { productNo, guestName, guestName2 = '' } = massageOrFloat
  const isCoupleFloat = productNo === PRODUCT_IDS.MassageFloatingTwo

  if (isCoupleFloat) {
    return `name1: ${guestName},name2: ${guestName2}`
  } else {
    return `guestName: ${guestName}`
  }
}
